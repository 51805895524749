import * as React from "react";
import {useState} from "react";
import Page from "../app/pages/page";
import {Link, Outlet, useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "./queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import {Tab, TabNavigation} from "../common/ui/tabNavigation";
import {YesNoDialog} from "../common/slds/modals/YesNoDialog";
import gql from "graphql-tag";
import Button from "../common/slds/buttons/button";
import {useAuthContext} from "../common/context/authContext";
import {useParams} from "react-router";

export function DEFAULT_DEVICE_TABLE_CONFIG(t) {
    return {
        cols: [
            {
                heading: t("device-type.device-table-config.heading.date", "Date"),
                cell: {
                    format: "{{date createdAt}}",
                }
            },
            {
                heading: t("device-type.device-table-config.heading.device", "Device"),
                cell: {
                    format: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
                    href: "#/organisation/devices/{{device.id}}/device-data",
                }
            },
            {
                heading: t("device-type.device-table-config.heading.address", "Address"),
                cell: {
                    format: "{{device.addr}}",
                    href: "#/organisation/devices/{{device.id}}/device-data",
                }
            },
        ]
    }
}

export const DEFAULT_DATA_TABLE_CONFIG = {
    cols: []
};

export function DeviceTypesDetailPage() {
    const auth = useAuthContext();
    const navigate = useNavigate();
    const deletePending = useState(false);

    const deviceTypeId = useParams().id;

    const deviceTypeResult = useQuery(QUERY_DEVICE_TYPE, {
        variables: {
            id: deviceTypeId
        }
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceTypeId},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]
    });

    const [deleteDeviceType] = useMutation(gql`
        mutation($id: ID!) {
            deleteDeviceType(id: $id)
        }`, {
        variables: {id: deviceTypeId}
    });

    const loadingHW = useGraphqlLoadingComponent(deviceTypeResult);
    if (loadingHW) {
        return loadingHW;
    }
    const deviceType = deviceTypeResult.data.deviceType;

    const canEdit = auth.hasRole("admin") || (deviceType.private && deviceType.organisationId === auth.organisationId() && auth.hasRole("org-admin"));

    return <Page
        trail={[<Link to={"/deviceTypes"} key={1}>Device Types</Link>,
            <Link to={`/deviceTypes/${deviceType.id}`} key={2}>{deviceType.displayName}</Link>]}
        title={`${deviceType.displayName}`}
        info={<>{deviceType.name} • {deviceType.numDevices} Devices  • {deviceType.private ? "Private" : "Public"}</>}
        actions={<> {canEdit && deviceType.numDevices === 0 &&
        <Button iconName={"delete"} className="slds-button--destructive" onClick={() => deletePending[1](true)}>Delete
            DeviceType</Button>}
        </>}
    >
        <TabNavigation tabs={[
            <Tab key={"overview"} to={"overview"}>Overview</Tab>,
            <Tab key={"parser"} to={"parser"}>Parser</Tab>,
            <Tab key={"device-table-config"} to={"deviceTableConfig"}>Device Table Config</Tab>,
            <Tab key={"data-table-config"} to={"dataTableConfig"}>Data Table Config</Tab>,
        ]}>
            <div className="slds-m-around--small">
                <Outlet context={{updateDeviceType, deviceType}} />
            </div>
            <YesNoDialog open={deletePending} title={"Really delete"}
                         text={"Do you really want to delete the device type?"} onYes={() => {
                deleteDeviceType().then(() => navigate("/deviceTypes"))
            }}/>
        </TabNavigation>
    </Page>
}
