import {NotifyUser} from "../common/userNotification";
import ParserForm from "../common/ui/parserForm";
import React from "react";
import {useAuthContext} from "../common/context/authContext";
import {useOutletContext} from "react-router";

const DeviceTypeParserForm = () => {
    const auth = useAuthContext();
    const {updateDeviceType, deviceType } = useOutletContext()
    const canEdit = auth.hasRole("admin") || (deviceType.private && deviceType.organisationId === auth.organisationId() && auth.hasRole("org-admin"));

    return <ParserForm readOnly={!canEdit}
                       parserCode={deviceType.parserCode}
                       inputCode={"{}"}
                       parserSelection={deviceType.parserEnabled ? "custom" : "default"}
                       saveParser={(values, actions) => {
                           const {parserCode, parserSelection} = values;

                           const parserEnabled = (parserSelection !== "default");

                           updateDeviceType({
                               variables: {
                                   id: deviceType.id,
                                   input: {
                                       parserCode: parserCode,
                                       parserEnabled: parserEnabled,
                                   }
                               }
                           }).catch((err) => {
                               NotifyUser.Error("Failed to save parser", err);
                           }).finally(() => {
                               actions.setSubmitting(false);
                           });
                       }}
    />
}

export default DeviceTypeParserForm