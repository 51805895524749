import gql from "graphql-tag";

export const GET_WMBUS_KEYS = gql`
    query ($orgId: ID!, $page: PaginationInputType, $sort: SortInputType, $filter:[FilterInputType!]){
        getWmbusKeysForOrganisation(orgId: $orgId, sort: $sort, page: $page, filter: $filter) {
            result {
                id
                encryptionKey
                meterId
                manufacturer
            }
            totalCount
        }
    }
`;
export const DELETE_WMBUS_KEY = gql`mutation($id: ID!){
    deleteWmbusKey(id: $id) {
        id
    }}
`;
export const UPDATE_WMBUS_KEY = gql`mutation($id: ID!, $wmbusKey: WmbusKeyInput!){
    updateWmbusKey(id: $id, input: $wmbusKey) {
        id
        encryptionKey
        meterId
        manufacturer
    }}
`;
export const CREATE_WMBUS_KEY = gql`mutation($wmbusKey: WmbusKeyInput!){
    createWmbusKey(input: $wmbusKey) {
        id
        encryptionKey
        meterId
        manufacturer
    }}
`;