import React, {useState} from "react";
import {Accordion as SldsAccordion, ButtonIcon} from '@salesforce/design-system-react';
import PropTypes from "prop-types"
import _ from "underscore";
import classNames from "classnames"
import {CustomPropTypes} from "../propTypes/customPropTypes";

export function Accordion(props) {
    let {children} = props;
    // Fix bug: SldsAccordion crashes when "children" is not an array
    if (!_.isArray(children)) {
        children = [children].filter(c => c !== undefined);
    }

    return <ul className="slds-accordion">
        {children}
    </ul>;
}

Accordion.propTypes = {
    ...SldsAccordion.propTypes,
    children: CustomPropTypes.children,
};

export function AccordionPanel(props) {
    let {children, id, panelContentActions, summary, onTogglePanel} = props;

    const [expanded, setExpanded] = useState(props.expanded === true || false);

    return <li className="slds-accordion__list-item">
        <section className={classNames("slds-accordion__section", {"slds-is-open": expanded})}>
            <div className="slds-accordion__summary">
                <h3 className="slds-accordion__summary-heading">
                    <button className="slds-button slds-button_reset slds-accordion__summary-action" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (onTogglePanel) {
                            onTogglePanel(e);
                        } else {
                            setExpanded(!expanded);
                        }
                    }}>
                        <ButtonIcon className="slds-accordion__summary-action-icon" category="utility" name="switch" position="left"/>
                        <span className="slds-truncate" title="Accordion summary">{summary}</span>
                    </button>
                </h3>
                {panelContentActions}
            </div>
            <div className="slds-accordion__content" id={id}>{expanded && children}</div>
        </section>
    </li>;
}

AccordionPanel.propTypes = {
    children: PropTypes.node,
    expanded: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    panelContentActions: PropTypes.node,
    onTogglePanel: PropTypes.func,
    summary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // isRequired removed because of ts
};