import * as React from "react";
import {useState} from "react";
import Page from "../app/pages/page";
import {Link, useNavigate} from "react-router-dom";
import {ButtonGroup} from "@salesforce/design-system-react";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../common/ui/form/formElements";
import {Modal} from "../common/slds";
import {Formik} from "formik";
import {useMutation, useQuery} from "@apollo/client";
import {Log} from "../common/log";
import {Form} from "../common/ui/form/formik";
import {usePagination} from "../common/hooks/usePagination";
import GenericDataTable from "../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../common/ui/data-table/column";
import {MUTATE_CREATE_DEVICE_TYPE, QUERY_DEVICE_TYPES} from "./queries";
import {useSearch} from "../common/hooks/useSearch";
import Button from "../common/slds/buttons/button";
import gql from "graphql-tag";
import {useAuthContext} from "../common/context/authContext";
import SingleLookupField from "../common/ui/lookup/singleLookupField";
import {useT} from "../common/i18n";
import DataTableCell from "../common/ui/data-table/cell";


export function DeviceTypesPage() {
    const t = useT();
    const auth = useAuthContext();
    const [showNewDeviceTypeModal, setShowNewDeviceTypeModal] = useState(false);
    const search = useSearch("");
    const navigate = useNavigate();
    const page = usePagination(25);

    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput(),
            orgId: auth.hasRole("admin") ? null : auth.organisationId()
        }
    });
    const organisationListResult = useQuery(gql`
        query($search: String) {
            getOrganisationList(search: $search) {
                id
                name
            }
        }`, {
        fetchPolicy: "network-only",
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        }
    });

    const [createDeviceType] = useMutation(MUTATE_CREATE_DEVICE_TYPE);

    return <Page
        trail={[<Link to="/deviceTypes" key={1}>{t("nav.device-types", "Device Types")}</Link>]}
        title={t("device-types.title", "Device Types")}
        actions={auth.hasRole("admin") || auth.hasRole("org-admin") ?
            <ButtonGroup><Button iconName={"add"}
                                 onClick={() => setShowNewDeviceTypeModal(true)}>{t("device-types.create-button", "Create Device Type")}</Button></ButtonGroup>
            : null
        }
    >
        <GenericDataTable id={"device-types-table"}
                          items={deviceTypesResult.data && deviceTypesResult.data.deviceTypes || []}
                          tableConfigDefault={{}}
                          gqlResult={deviceTypesResult} page={page} search={search}>
            <DataTableColumn label={t("device-types.name", "Name")} property="name">
                <DataTableCell render={(props) => (
                    <Link
                        to={props.item.id}>{props.item.displayName ? props.item.displayName : t("device-types.no-name", "- no name -")}</Link>
                )}/>
            </DataTableColumn>
        </GenericDataTable>

        <Modal isOpen={showNewDeviceTypeModal} onRequestClose={() => setShowNewDeviceTypeModal(false)}>
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    const orgId = !auth.hasRole("admin") ?
                        auth.organisationId() : (
                            !!values.organisation?.id ? values.organisation.id : auth.organisationId()
                        )

                    createDeviceType({
                        variables: {
                            name: values.name,
                            input: {
                                displayName: values.displayName,
                                organisationId: orgId,
                                private: true
                            }
                        }
                    }).then((result) => {
                        navigate("/deviceTypes/" + result.data.createDeviceType.id)
                    }, (e) => {
                        actions.setFieldError("global", e);
                        Log.Error("Failed to create Device Type", e);
                    }).finally(() => {
                        actions.setSubmitting(false);
                    })
                }}>
                <Form>
                    <SldsInputField name={"displayName"} label={t("device-types.display-name", "Display Name")}
                                    placeholder={t("device-types.display-name-placeholder", "Device Type Name")}
                                    required={true}/>
                    <SldsInputField name={"name"} label={t("device-types.type-name", "Type Name")}
                                    placeholder={t("device-types.type-name-placeholder", "device-type-name")}
                                    required={true}
                                    validate={(value) => /^[a-zA-Z0-9]+[a-zA-Z0-9\-]*[a-zA-Z0-9]+$/.test(value) ? null : t("device-types.validation.only-use", "Only use {{allowed}}", {allowed: "[0-9, a-z, A-Z, -]"})}
                    />

                    {auth.hasRole("admin") ? <SingleLookupField name={"organisation"}
                                                                label={t("device-types.organisation", "Organisation") + " " + t("device-types.optional", "(optional)")}
                                                                autoFocus={false} required={false}
                                                                loadSuggestions={(keyword) => organisationListResult
                                                                    .refetch({search: keyword})
                                                                    .then(result => result.data?.getOrganisationList)
                                                                }
                                                                titleExtractor={it => it.name}
                                                                subtitleExtractor={it => it.id}
                    /> : null}
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <FormActions>
                        <SubmitButtonField>{t("common.button.create", "Create")}</SubmitButtonField>
                        <CancelButtonField onClick={() => setShowNewDeviceTypeModal(false)}/>
                    </FormActions>
                </Form>
            </Formik>
        </Modal>
    </Page>
}