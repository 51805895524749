import React from "react"
import VerticalNav, {
    VerticalNavEntries,
    VerticalNavEntry,
    VerticalNavSection,
    VerticalNavTitle,
} from "../../common/slds/verticalNavigation/verticalNavigation"
import { Outlet } from "react-router-dom"
import { useT } from "../../common/i18n"
import { useAuthContext } from "../../common/context/authContext"


export default function OrgConfigPage() {
    const auth = useAuthContext()
    const t = useT()
    if (auth.hasRole("org-admin") || auth.hasRole("admin")) {
        return <div className="slds-container--fluid">
            <div className="slds-grid">
                <div className="slds-col slds-no-flex">
                    <VerticalNav>
                        <VerticalNavSection>
                            <VerticalNavTitle>{t("org.config.nav.heading", "Organisation")}</VerticalNavTitle>
                            <VerticalNavEntries>
                                <VerticalNavEntry
                                    to={"/organisation/config/settings"}>{t("org.config.nav.settings", "Settings")}</VerticalNavEntry>
                                <VerticalNavEntry
                                    to={"/organisation/config/wmbus"}>{t("org.config.nav.wmbus-keys", "wMbus Keys")}</VerticalNavEntry>
                                <VerticalNavEntry
                                    to={"/organisation/config/users"}>{t("org.config.nav.users", "Users")}</VerticalNavEntry>
                                <VerticalNavEntry
                                    to={"/organisation/config/suborganisations"}>{t("org.config.nav.suborganisations", "Sub Organisations")}</VerticalNavEntry>
                                <VerticalNavEntry
                                    to={"/hardwareActivation/org/"}>{t("org.config.nav.hardware-activation", "Hardware Activation")}</VerticalNavEntry>
                            </VerticalNavEntries>
                        </VerticalNavSection>
                    </VerticalNav>
                </div>
                <div className="slds-col">
                    <Outlet />
                </div>
            </div>
        </div>
    } else {
        return null
    }

}