import gql from "graphql-tag";
import {useT} from "../../../../common/i18n";
import {useNotificationContext} from "../../../../notifications/notificationContext";
import {usePagination} from "../../../../common/hooks/usePagination";
import {useMutation} from "@apollo/client";
import {TabsPanel} from "@salesforce/design-system-react";
import {Field, Formik} from "formik";
import {Form} from "../../../../common/ui/form/formik";
import {
    DateTimePicker,
    FormActions,
    SldsFormElementCompound,
    SldsFormElementRow,
    SubmitButtonField
} from "../../../../common/ui/form/formElements";
import GenericDataTable from "../../../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../../../common/ui/data-table/column";
import * as React from "react";

const MUTATION_RESEND_DEVICE_PARSED_DATA = gql`
    mutation resendDeviceParsedData($deviceIds: [ID!], $start:  DateTime!, $end:  DateTime!) {
        resendDeviceParsedData(deviceIds: $deviceIds, start: $start, end: $end)
    }
`;

export const DeviceDataResendTab = (props) => {
    const t = useT();
    const notify = useNotificationContext();
    const page = usePagination();

    const [mutationResendParsedData] = useMutation(MUTATION_RESEND_DEVICE_PARSED_DATA)


    let multiSelection = props.multiSelection

    return <TabsPanel label="Resend Data">
        <Formik
            initialValues={{
                until: new Date(Date.now()),
                from: new Date(Date.now()),
            }}
            onSubmit={
                (values) => {
                    let deviceIds = []
                    multiSelection.selections.forEach((currentValue) => {
                        deviceIds.push(currentValue.id)
                    })
                    mutationResendParsedData({variables: {
                            deviceIds: deviceIds,
                            end: values.until,
                            start: values.from,
                        }
                    }).then(() => {
                        notify.info("finished resending")
                    }, (err) => {
                        notify.error("Failed to resend:", err);
                    });
                }}
            // onReset={(values, actions) => {
            //     log.Debug("Clearing Filters");
            //     filters.setFilter([])
            // }}
            render={() => {
                return <Form>
                    <SldsFormElementCompound>
                        <SldsFormElementRow>
                            <Field component={DateTimePicker} submitFormOnChange={false} label={t("filter-panel.from", "From")} name="from"/>
                            <Field component={DateTimePicker} submitFormOnChange={false} label={t("filter-panel.until", "Until")} name="until"/>
                        </SldsFormElementRow>
                    </SldsFormElementCompound>
                    <FormActions>
                        <SubmitButtonField iconName={"play"}>Run Replay</SubmitButtonField>
                    </FormActions>
                </Form>
            }
            }
        />

        <br/>
        <h1>{t("device.bulkoperation.table-heading","Affected Devices")}:</h1>
        <GenericDataTable id={"bulk-operation-table"} items={multiSelection.selections} page={page}
                          tableConfigDefault={{}}
                          selection={multiSelection}>
            <DataTableColumn label={t("device.table-config.heading.serial", "Serial")} property={"serial"}
                             render={(props) => {
                                 return props.item.serial
                             }}/>
            <DataTableColumn label={t("device.table-config.heading.address", "Address")} property="addr" title={"Addr"}
                             render={(props) => {
                                 return props.item.addr
                             }}/>
            <DataTableColumn label={t("device.table-config.heading.name", "Name")} property="name" title={"Name"}
                             render={(props) => {
                                 return props.item.name
                             }}/>
            <DataTableColumn label={t("device.table-config.heading.type", "Type")} property={"deviceType"}
                             render={(props) => {
                                 return props.item.deviceType.displayName
                             }}/>
            <DataTableColumn label={t("device.table-config.heading.app", "App")} property={"app"}
                             render={(props) => {
                                 return props.item.app ? props.item.app.name : null
                             }}/>
            <DataTableColumn label={t("common.organisation", "Organisation")} property={"Organisation"}
                             render={(props) => {
                                 return props.item.organisation ? props.item.organisation.name : ""
                             }}/>
        </GenericDataTable>
    </TabsPanel>
}