import React from "react";
import {Field, Form, Formik} from "formik";
import {SldsInput, SldsTextarea,} from "../../../common/ui/form/formElements";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {useT} from "../../../common/i18n";
import {useParams} from "react-router";

const QUERY_DEVICE = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            id
            deviceCertificate {
                id
                deviceCertificateHash
                trustStatus
                createdAt
                updatedAt
            }
            certEnforcesDtls
        }
    }
`;

export default function DeviceSecurity() {
    const t = useT();
    const devId = useParams().deviceId;

    const deviceResult = useQuery(QUERY_DEVICE, {
        fetchPolicy: 'cache-first',
        variables: {devId: devId},
    });

    const loading = useGraphqlLoadingComponent(deviceResult);
    if (loading) {
        return loading;
    }

    const {device} = deviceResult.data;

    if (device.deviceCertificate == null) {
        return <div className="slds-m-left--x-small">
            {t("device.security.no-cert-assigned", "No associated DTLS Certificate for Device. Device will fallback to default communication.")}
            </div>
    }

    return <div className="slds-m-left--x-small">
        {t("device.security.certEnforcesDtls","Enforce DTLS if Cert is present")}&nbsp;
        <input type="checkbox" name="certEnforcesDtls" id={"certEnforcesDtls"} checked={device.certEnforcesDtls}
               readOnly={true}/>
        <Formik
            initialValues={device}
            enableReinitialize={true}
            initialStatus={{
                readOnly: true,
                canEdit: false //isAdmin || isOrgAdmin
            }}
            render={(formik) => {
                const {readOnly} = formik.status;

                return <Form className="slds-form slds-form-element_stacked">
                    <Field component={SldsInput} name="deviceCertificate.id"  id="deviceCertificate.id" label={t("device.security.certificate-id", "Certificate Id")}
                           readOnly={readOnly}/>
                    <Field component={SldsTextarea} name="deviceCertificate.deviceCertificateHash"  id="deviceCertificate.deviceCertificateHash" label={t("device.security.certificate-hash", "Certificate Hash")}/>
                    <Field component={SldsTextarea} name="deviceCertificate.trustStatus"  id="deviceCertificate.trustStatus" label={t("device.security.trust-status", "Trust Status")}/>
                    <Field component={SldsTextarea} name="deviceCertificate.updatedAt"  id="deviceCertificate.updatedAt" label={t("device.security.updated-at", "Updated At")}/>
                    <Field component={SldsTextarea} name="deviceCertificate.createdAt"  id="deviceCertificate.createdAt" label={t("device.security.created-at", "Created At")}/>
                </Form>;
            }}
        />

    </div>
}