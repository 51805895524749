import * as React from "react";
import {useState} from "react";
import * as PropTypes from "prop-types";
import gql from "graphql-tag";
import * as log from "../log";
import CodeEditor from "./codeEditor";
import {Button} from "@salesforce/design-system-react";
import {FormActions, SldsFormElement, SldsFormElementControl, SldsRadio, SldsRadioGroup} from "./form/formElements";
import {Field, Form, Formik} from "formik";
import he from "he"
import {CustomPropTypes} from "../propTypes/customPropTypes";
import {useQuery} from "@apollo/client";
import {FrontendConfigContext} from "../context/frontendConfigContext";
import {useT} from "../i18n";

const parserTemplate = `function Parse(input) {
  // Decode an incoming message to an object of fields.
  var decoded = {input: input};

  return decoded;
}
`;
const QUERY_TEST_PARSER = gql`
    query testParser($parser: String!, $input: String!) {
        output: testParser(parser:$parser, input:$input)
    }
`;

export default function ParserForm(props) {
    const {inputCode, defaultParserCode, parserSelection, readOnly} = props;
    const initialParserCode = props.parserCode;
    const t = useT()

    const [parserCode, setParserCode] = useState(props.parserCode);

    const queryTestParser = useQuery(QUERY_TEST_PARSER, {
        skip: true,
        fetchPolicy: 'no-cache',
        partialRefetch: true,
    });

    function executeParser(formik) {
        const {parserCode, defaultParserCode, inputCode} = formik.values;
        const isDefaultParser = formik.values.parserSelection === "default";

        log.Debug("ExecuteParser");
        queryTestParser.refetch({
            parser: isDefaultParser ? defaultParserCode : parserCode,
            input: inputCode,
        }).then((result) => {
            log.Debug("Parser result: ", result);
            //const outputObj = JSON.parse(result.data.output);
            //const outputStr = JSON.stringify(outputObj, null, 2);

            formik.setFieldValue("outputCode", he.decode(result.data.output));
        }, (err) => {
            let msg = err.message.replace("GraphQL error: ", "");
            formik.setFieldValue("outputCode", msg);
            //alert("Failed to execute parser: " + err.message);
        });
    }

    function onSubmit(values, actions) {
        log.Debug("submit:", values);
        const {saveParser} = props;
        saveParser(values, actions);
    }



    return <Formik onSubmit={(values, actions) => onSubmit(values, actions)}
                   initialValues={{
                       parserSelection: parserSelection,
                       defaultParserCode: defaultParserCode ? defaultParserCode : parserTemplate,
                       parserCode: parserCode ? parserCode : parserTemplate,
                       inputCode: inputCode,
                       outputCode: "{}",
                   }}
    >
        {(formik) => {
            const isDefaultParser = formik.values.parserSelection === "default";
            return <Form>
                <div className="slds-grid slds-wrap slds-m-top--medium">
                    <div className="slds-col slds-size--1-of-1 slds-large-size--1-of-2 slds-m-bottom--small">
                        <ParserHeader/>
                        <SldsRadioGroup className="slds-m-bottom--x-small" readOnly={readOnly}>
                            <ul className="slds-list--horizontal">
                                <li><Field component={SldsRadio} name="parserSelection" label={t("device.settings.parser-default", "default")} id={"radio-default"} value={"default"}/></li>
                                <li><Field component={SldsRadio} name="parserSelection" label={t("device.settings.parser-custom", "custom")} id={"radio-custom"} value={"custom"}/></li>
                            </ul>
                        </SldsRadioGroup>
                        <SldsFormElement className="slds-p-right--small">
                            <SldsFormElementControl>
                                <CodeEditor id={"editor-parser"}
                                            minLines={15}
                                            maxLines={30}
                                            value={formik.values[isDefaultParser ? "defaultParserCode" : "parserCode"]}
                                            readOnly={readOnly || isDefaultParser}
                                            width={"100%"}
                                            onChange={(val) => {
                                                formik.setFieldValue("parserCode", val);
                                                setParserCode(val);
                                            }}/>
                            </SldsFormElementControl>
                        </SldsFormElement>

                        <FormActions>
                            <Button type={"button"} variant={"neutral"}
                                    onClick={() => executeParser(formik)}>
                                Test Parser
                            </Button>
                            {!readOnly ?
                                <Button type={"submit"} variant={"brand"} disabled={formik.isSubmitting}>
                                    Save Parser
                                </Button> : null}
                            {!readOnly ?
                                <Button type={"button"} variant={"destructive"} disabled={formik.isSubmitting || isDefaultParser}
                                    onClick={() => formik.setFieldValue("parserCode", initialParserCode)}>
                                Discard changes
                               </Button>
                                : null}
                        </FormActions>
                    </div>
                    <div className="slds-col slds-size--1-of-1 slds-large-size--1-of-2 ">
                        <div className="slds-text-heading--medium slds-m-bottom--small">Test Input</div>
                        <CodeEditor id={"editor-input"} defaultValue={"{}"}
                                    maxLines={20}
                                    width={"100%"}
                                    value={formik.values["inputCode"]}

                                    onChange={(val) => {
                                        formik.setFieldValue("inputCode", val);
                                    }}
                        />
                        <div className="slds-text-heading--medium slds-m-bottom--small slds-m-top--small">Output</div>
                        <CodeEditor id={"editor-output"} defaultValue={`{}`}
                                    maxLines={30}
                                    width={"100%"}
                                    readOnly={true}
                                    value={formik.values["outputCode"]}
                                    onChange={(val) => {
                                        formik.setFieldValue("outputCode", val);
                                    }}
                        />
                    </div>
                </div>
            </Form>
        }}
    </Formik>
}

ParserForm.propTypes = {
    parserSelection: PropTypes.oneOf(["default", "custom"]).isRequired,
    defaultParserCode: PropTypes.string,
    parserCode: PropTypes.string.isRequired,
    inputCode: PropTypes.string.isRequired,
    saveParser: PropTypes.func.isRequired,
    client: CustomPropTypes.deprecated("Graphql client not needed anymore due to hooks"),
};

export function ParserHeader() {
    const parserDocUrl = React.useContext(FrontendConfigContext)?.parserDocUrl;
    if (parserDocUrl){
        return <div className="slds-text-heading--medium slds-m-bottom--small">Parser (<a href={parserDocUrl} target="_blank">Help</a>)</div>
    } else {
        return  <div className="slds-text-heading--medium slds-m-bottom--small">Parser </div>
    }

}