import gql from "graphql-tag";
import {useT} from "../../../../common/i18n";
import {useNotificationContext} from "../../../../notifications/notificationContext";
import {useMutation} from "@apollo/client";
import {usePagination} from "../../../../common/hooks/usePagination";
import * as React from "react";
import {useState} from "react";
import {TabsPanel} from "@salesforce/design-system-react";
import GenericDataTable from "../../../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../../../common/ui/data-table/column";
import Tooltip from "../../../../common/slds/tooltip/tooltip";
import {Icon} from "../../../../common/slds/icons/icon";
import {Form} from "../../../../common/ui/form/formik";
import {FormActions, SubmitButtonField} from "../../../../common/ui/form/formElements";
import {Formik} from "formik";
import DeviceTypeLookupField from "../../../../components/deviceType/deviceTypeLookupField";
import {useAuthContext} from "../../../../common/context/authContext";
import _ from "lodash";

const MUTATION_UPDATE_DEVICE = gql`
    mutation updateConfig($devId: ID!, $input: DeviceInput!) {
        updateDevice(id: $devId, input: $input) {
            id
            deviceType {
                id
                displayName                
            }
        }
    }
`;

export const ChangeDeviceTypeTab = (props) => {
    const auth = useAuthContext()
    const t = useT();
    const notify = useNotificationContext();
    const [updateDevice] = useMutation(MUTATION_UPDATE_DEVICE);
    const page = usePagination();
    const [results, setResults] = useState(new Map());

    let multiSelection = props.multiSelection
    return <TabsPanel label={t("device.bulkoperation.devicetype.label", "Device Type")}>
        <Formik
            initialValues={{
            }}
            onSubmit={
                (values) => {
                    setResults(new Map())
                    let promises = []
                    multiSelection.selections.forEach((currentValue) => {
                        promises.push(updateDevice({
                                variables: {
                                    devId: currentValue.id,
                                    input: {deviceTypeId: values.deviceType.id}
                                },
                            }).catch((err) => {
                                setResults(results.set(currentValue.id, err))
                                return true
                            }).finally(() => {
                                setResults(results.set(currentValue.id, false))
                                return false
                            })
                        )
                    })
                    Promise.all(promises).then((values) => {
                        const failed = _.filter(values, (v) => {
                            return v === true
                        })
                        if (failed.length > 0) { //"Only use {{allowed}}", {allowed: "[0-9, a-z, A-Z, -]"}
                            notify.error(t("device.bulkoperation.devicetype.failed", "DeviceType change failed for {{ammount}} devices", {ammount: failed.length}), "change of devicetypes failed on some devices");
                        } else {
                            notify.success(t("device.bulkoperation.devicetype.success", "Successfully changed devicetypes"));
                        }
                    });
                }}
            render={() => {
                return <Form>
                    <DeviceTypeLookupField orgId={auth.organisationId()}/>
                    <FormActions>
                        <SubmitButtonField iconName={"play"}>Run Replay</SubmitButtonField>
                    </FormActions>
                </Form>
            }
            }
        />

        <br/>
        <h1>{t("device.bulkoperation.table-heading", "Affected Devices")}:</h1>
        <GenericDataTable id={"bulk-operation-table"} items={multiSelection.selections} page={page}
                          tableConfigDefault={{}}
                          selection={multiSelection}>
            <DataTableColumn label={t("device.table-config.heading.serial", "Serial")} property={"serial"}
                             render={(props) => {
                                 return props.item.serial
                             }}/>
            <DataTableColumn label={t("device.table-config.heading.address", "Address")} property="addr" title={"Addr"}
                             render={(props) => {
                                 return props.item.addr
                             }}/>
            <DataTableColumn label={t("device.table-config.heading.name", "Name")} property="name" title={"Name"}
                             render={(props) => {
                                 return props.item.name
                             }}/>
            <DataTableColumn label={t("device.table-config.heading.type", "Type")} property={"deviceType"}
                             render={(props) => {
                                 return props.item.deviceType.displayName
                             }}/>
            <DataTableColumn label={t("device.table-config.heading.app", "App")} property={"app"}
                             render={(props) => {
                                 return props.item.app ? props.item.app.name : null
                             }}/>
            <DataTableColumn label={t("common.organisation", "Organisation")} property={"Organisation"}
                             render={(props) => {
                                 return props.item.organisation ? props.item.organisation.name : ""
                             }}/>
            <DataTableColumn label={t("device.bulkoperation.devicetype.table.result", "Changed DeviceType")} property={"result"}
                             render={(props) => {
                                 if (results.get(props.item.id) == null) {
                                     return null
                                 }

                                 return <Tooltip left="-10px" top="-50px"
                                                 content={results.get(props.item.id) ? results.get(props.item.id) : t("common.success", "Success")}>
                                     {results.get(props.item.id) ? <Icon name="close" size={"small"}/> :
                                         <Icon name="check" size={"small"}/>}
                                 </Tooltip>
                             }}
            />
        </GenericDataTable>
    </TabsPanel>
}