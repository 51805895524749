import React, {useEffect, useState} from "react";
import Page from "../../common/ui/page";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {useAuthContext} from "../../common/context/authContext";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import {usePagination} from "../../common/hooks/usePagination";
import {useSort} from "../../common/hooks/useSort";
import {useCsvExport} from "../../common/hooks/useCsvExport";
import {useSearch} from "../../common/hooks/useSearch";
import {QUERY_DEVICE_TYPE, QUERY_DEVICE_TYPES} from "../deviceData/deviceDataPage";
import {Navigate, useMatch} from "react-router-dom";
import {DeviceTypesNav} from "../deviceData/deviceTypesNav";
import Url from "../../common/url";
import {Log} from "../../common/log";
import {useT} from "../../common/i18n";
import {useMultiSelection} from "../../common/hooks/useMultiSelection";
import {ButtonGroup} from "@salesforce/design-system-react";
import Button from "../../common/slds/buttons/button";
import {DeviceBulkOperationPage} from "./device/deviceBulkOperationPage";
import {useFilter} from "../../common/hooks/useFilter";
import {useParams} from "react-router";


function batteryCellValueLocalized(t) {
    const BATTERY_CELL_VALUE_TEMPLATE = `{{#if properties.[platform.powerStatus].value}}
{{#if (gte properties.[platform.powerStatus].value 7)}}
<div title="REPLACE_GOOD"><span class="slds-icon_container "><svg class="slds-icon slds-icon--x-small slds-icon-text-default" aria-hidden="true">
<?xml version="1.0" encoding="UTF-8"?><svg version="1.1" viewBox="0 0 6.8189 4.2441" xmlns="http://www.w3.org/2000/svg">
<g transform="matrix(0 -.46373 .45806 0 .37646 4.44)">
<g transform="matrix(.031559 0 0 .03462 -36.568 -16.557)">
<rect x="1182.1" y="480.22" width="270" height="394.29" ry="28.571" fill="none" stroke="#00a900" stroke-width="20"/>
<rect x="1280.4" y="460.91" width="79.543" height="10.057" ry="5.0286" fill="none" stroke="#00a900" stroke-width="12.8"/>
<g fill="#00a900">
<rect x="1210.7" y="767.36" width="212.86" height="71.429" ry="0"/>
<rect x="1210.7" y="683.17" width="212.86" height="71.429" ry="0"/>
<rect x="1210.7" y="598.98" width="212.86" height="71.429" ry="0"/>
<rect x="1210.7" y="514.79" width="212.86" height="71.429" ry="0"/></g></g></g></svg></svg></span></div>{{else}}{{#if (gte properties.[platform.powerStatus].value 4)}}<div title="REPLACE_LOW"><span class="slds-icon_container "><svg class="slds-icon slds-icon--x-small slds-icon-text-default" aria-hidden="true"><?xml version="1.0" encoding="UTF-8"?>
<svg version="1.1" viewBox="0 0 6.7733 4.2333" xmlns="http://www.w3.org/2000/svg"><rect transform="rotate(90)" x=".26422" y="-6.5091" width="3.7049" height="5.983" ry=".43355" fill="none" stroke="#fc0" stroke-width=".52843"/>
<rect transform="rotate(90)" x="1.6132" y="-.32171" width="1.0915" height=".15262" ry=".076306" fill="none" stroke="#fc0" stroke-width=".33819"/>
<rect transform="rotate(90)" x=".65355" y="-5.9104" width="2.9208" height="1.0839" ry="0" fill="#fc0" stroke-width=".40311"/>
<rect transform="rotate(90)" x=".65006" y="-4.4923" width="2.9208" height="1.0839" ry="0" fill="#fc0" stroke-width=".40311"/>
</svg></svg></span></div>{{else}}<div title="REPLACE_DRAINED"><span class="slds-icon_container "><svg class="slds-icon slds-icon--x-small slds-icon-text-default" aria-hidden="true">
<?xml version="1.0" encoding="UTF-8"?><svg version="1.1" viewBox="0 0 6.836 4.2181" xmlns="http://www.w3.org/2000/svg"><g transform="translate(.82805 -.83211)">
<g transform="matrix(0 -.014545 .015898 0 -9.6337 5.6986)"><rect x="54.571" y="579.61" width="270" height="394.29" ry="28.571" fill="none" stroke="#d40000" stroke-width="20"/>
<rect x="152.86" y="560.3" width="79.543" height="10.057" ry="5.0286" fill="#f00" stroke="#d40000" stroke-width="12.8"/>
<rect x="83.143" y="866.75" width="212.86" height="71.429" ry="0" fill="#d40000"/></g></g></svg></svg></span></div>{{/if}}{{/if}}{{else}}{{/if}}`;


    let value = BATTERY_CELL_VALUE_TEMPLATE.replace("REPLACE_GOOD", t("device.battery.good","good"))
    value = value.replace("REPLACE_LOW", t("device.battery.low","low"))
    return value.replace("REPLACE_DRAINED",t("device.battery.drained","drained"))
}

const QUERY_DEVICES = gql`
    query($orgId: ID!, $devTypeId: ID, $page: PaginationInputType, $sort: SortInputType, $search: String, $filter:[FilterInputType!]) {
        devices(orgId: $orgId, sort: $sort, page: $page, search: $search, devTypeId: $devTypeId, filter: $filter) {
            createdAt
            id
            addr
            name
            description
            configRaw
            propertiesRaw
            lastReceived
            serial
            initialConfigRaw
            firmwareVersion
            deviceType {
                id
                displayName
            }
            app {
                id
                appId
                name
            }
        }
    }
`;

const DEFAULT_DEVICE_TABLE_CONFIG = (t) => {
    return {
        cols: [
            {
                heading: t("device.table-config.heading.serial", "Serial"),
                csvFormat: "{{serial}}",
                cell: {
                    format: "{{serial}}"
                }
            },
            {
                heading: t("device.table-config.heading.type", "Type"),
                csvFormat: "{{deviceType.displayName}}",
                cell: {
                    format: "{{deviceType.displayName}}"
                }
            },
            {
                heading: t("device.table-config.heading.app", "App"),
                csvFormat: "{{app.name}}",
                cell: {
                    format: "{{app.name}}"
                }
            },
            {
                heading: t("device.table-config.heading.last-received", "Last Received"),
                sortProperty: "lastReceived",
                csvFormat: "{{date lastReceived}}",
                cell: {
                    format: "{{date lastReceived}}"
                }
            },
            {
                heading: t("device.table-config.heading.description", "Description"),
                csvFormat: "{{description}}",
                cell: {
                    format: "{{description}}"
                }
            },
            {
              heading: t("device.table-config.heading.battery", "Battery"),
              cell: {
                  format: batteryCellValueLocalized(t),
                  isHtml: true,
              },
              sortProperty: "properties.platform\".\"powerStatus.value"
            },
        ]
    }
};

const DevicesPage = () => {
    const t = useT()
    const auth = useAuthContext();
    let {devTypeId} = useParams();
    const orgId = auth.organisationId();
    const noSelectMatch = useMatch("/organisation/devices/type");
    if (devTypeId === "all") {
        devTypeId = undefined;
    }

    const search = useSearch();
    const multiSelection = useMultiSelection();

    const devicesResult = useQuery(QUERY_DEVICES, {
        variables: {
            orgId: auth.organisationId(),
            search: search.getGraphqlSearchInput(),
            devTypeId: devTypeId,
            page:  {
                  offset: 0,
                  limit: 100,
            },
            sort: {field: 'lastReceived', direction: "desc"},
        }
    });
    //umbau alle hooks umdrehen
    const filters = useFilter(false,[], devicesResult.refetch)
    const sort = useSort({property: 'lastReceived', direction: "desc"}, devicesResult.refetch);
    const page = usePagination(100, devicesResult.refetch)

    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES, {
            variables: {
                orgId: orgId,
                onlyWithExistingDevices: true
            }
        }
    );

    const devTypeResult = useQuery(QUERY_DEVICE_TYPE, {
            skip: !devTypeId,
            variables: {
                devTypeId: devTypeId,
            }
        }
    );

    const [showBulkOperationPage, setShowBulkOperationPage] = useState(false);

    const devType = devTypeResult.data?.deviceType;
    const deviceTypes = deviceTypesResult?.data?.deviceTypes;
    const devices = devicesResult.data?.devices?.map((d) => {
        let properties;
        try {
            properties = JSON.parse(d.propertiesRaw) || {};
        } catch (err) {
            Log.Error("Failed to parse device propertiesRaw", err);
        }
        let initialConfig
        try {
            initialConfig = JSON.parse(d.initialConfigRaw) || {}
        } catch (err) {
            Log.Error("Failed to parse device initialConfigRaw", err);
        }
        return {
            ...d,
            properties: properties,
            initialConfig: initialConfig
        };
    });
    page.setPageItemCount(devices?.length || 0);

    const csvExport = useCsvExport(QUERY_DEVICES, {
        variables: {
            orgId: auth.organisationId(),
            sort: sort.getGraphqlSortInput()
        },
        dataExtractor: d => d.data.devices.map((d) => {
            let properties;
            try {
                properties = JSON.parse(d.propertiesRaw) || {};
            } catch (err) {
                Log.Error("Failed to parse device propertiesRaw", err);
            }
            return {
                ...d,
                properties: properties,
            };
        })
    });

    useEffect(() => {
        Log.Debug("Selection: ", multiSelection.selections )
    },[multiSelection.selections]);

    if (deviceTypes?.length >= 1 && noSelectMatch) {
        return <Navigate to={Url.join(noSelectMatch.pathname, "all")}/>;
    }

    let tableConfig = devType?.deviceTableConfigRaw && JSON.parse(devType.deviceTableConfigRaw);

    if (!tableConfig) {
        tableConfig = DEFAULT_DEVICE_TABLE_CONFIG(t);
    }

    const prefixCols = [{
        heading: t("device.table-config.heading.name", "Name"),
        sortProperty: "name",
        csvFormat: "{{#if name}}{{name}}{{else}}- no name -{{/if}}",
        cell: {
            format: "{{#if name}}{{name}}{{else}}- no name -{{/if}}",
            href: "/#/organisation/devices/{{id}}/device-data",
        }
    },
        {
            heading: t("device.table-config.heading.address", "Address"),
            sortProperty: "addr",
            csvFormat: "{{addr}}",
            cell: {
                format: "{{addr}}",
                href: "/#/organisation/devices/{{id}}/device-data",
            }
        }];


    if (showBulkOperationPage){
        return <DeviceBulkOperationPage setShowBulkOperationPage={(b) => setShowBulkOperationPage(b)} page={page} multiSelection={multiSelection} />
    }

    return <div className="slds-container--fluid">
        <div className="slds-grid">
            <div className="slds-col slds-no-flex">
                <DeviceTypesNav deviceTypes={deviceTypes} baseUrl={"/organisation/devices/type"} showAll={true}/>
            </div>
            <div className="slds-col">
                <Page title={t("devices.page.title", "Devices")} trail={[]}
                      actions={<ButtonGroup>
                          <Button iconName={"edit"} onClick={() =>  setShowBulkOperationPage(true)} disabled={!multiSelection.selections.length} >Bulk Operations</Button>

                      </ButtonGroup>}
                >
                    <GenericDataTable
                        items={devices}
                        id="DevicesTable"
                        selection={multiSelection}
                        selectRows={true}
                        gqlResult={devicesResult}
                        csvExport={csvExport}
                        page={page}
                        sort={sort}
                        search={search}
                        filters={filters}
                        fixedLayout={true}
                        tableConfigDefault={tableConfig}
                        prefixCols={prefixCols}
                    />
                </Page>
            </div>
        </div>
    </div>;
};

export default DevicesPage;

