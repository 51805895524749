import React, {useEffect, useState} from "react";
import Page from "../../common/ui/page";
import {Formik} from "formik";
import {Form} from "../../common/ui/form/formik";
import {FormActions, SldsFileSelectorField, SubmitButtonField} from "../../common/ui/form/formElements";
import {Log} from "../../common/log";
import Papa from "papaparse";
import Json from "../../common/ui/json";
import DataTable, {Col, HeaderCol, Row, TableBody, TableHead} from "../../common/slds/dataTable";
import {Icon} from "../../common/slds/icons/icon";
import Tooltip from "../../common/slds/tooltip/tooltip";
import gql from "graphql-tag";
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {MUTATE_CREATE_DEVICE} from "../queries";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import ProgressBar from "../../common/slds/progressBar/progressBar";
import DescriptionList, {DescriptionListEntry} from "../../common/slds/descriptionList/descriptionList";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import OrganisationLookupField from "../../components/organisation/organisationLookupField";
import DeviceTypeLookupField from "../../components/deviceType/deviceTypeLookupField";

function useCsvImport() {
    let [csv] = useState("");
    let [results, setResults] = useState("");

    return {
        csvString: csv,
        results: results,

        /**
         * @param file {File}
         */
        loadFile: (file) => {
            Log.Debug("Loading CSV File");
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: (results) => {
                    Log.Debug("Loading CSV File - DONE");
                    setResults(results);
                }
            });
            /*
            let reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                setCsv(reader.result);
            };*/
        }
    };
}

const ImportStatus = {
    Pending: "pending",
    Done: "done",
    Failed: "failed",
    Skipped: "skipped",
};

/**
 * A device to be imported
 */
class ImportDevice {
    address;
    firmware;
    name;
    serial;
    config;
    configParseError;
    existingCount; // how many devices with this address exists already?
    importStatus; // See: ImportStatus
    importError;

    /**
     * Function that is calles when the device should be created in backend.
     *
     * @returns {undefined | Promise<T>}
     */
    createDeviceHandler;

    constructor(addr) {
        this.address = addr;
        this.importStatus = ImportStatus.Pending;
    }


    /**
     * Execute the actual import
     */
    import(meta) {
        if (this.existingCount > 0) {
            Log.Debug("Skipped existing device", this.address, this);
            this.importStatus = ImportStatus.Skipped;
            return Promise.resolve();
        }


        Log.Debug("Create Device:", {
            addr: this.address,
            name: this.name,
            serial: this.serial,
            deviceType: meta.deviceType?.id,
            organisation: meta.organisation?.id,
            activationGroup: meta.activationGroupId,
        });

        return Promise.resolve(this.createDeviceHandler(this, meta)).then(() => {
            this.existingCount++; // One more should exist now :)
            this.importStatus = ImportStatus.Done;
            this.importError = null;
        }).catch((err) => {
            this.importError = err;
            this.importStatus = ImportStatus.Failed;
        });


    }
}

const QUERY_DEVICES_BY_ADDRESS = gql`
    query ($devAddr: String!) {
        devices(filter: [{field: "addr", op: "in", value: $devAddr}]) {
            id
            addr
            initialConfigRaw
        }
    }
`;
const QUERY_ACTIVATION_GROUPS = gql`
    query($search: String) {
        getActivationGroupList(search: $search) {
            id
            nr
        }
    }`;

export default function ImportHardwarePage() {
    const [devices, setDevices] = useState([]);
    const [, setReloadTable] = useState(0);
    // Hide the table during import to save a lot of rendering work!
    const [importRunning, setImportRunning] = useState(false);

    let importedCnt = 0;
    devices.forEach((d) => {
        if (d.importStatus !== ImportStatus.Pending) {
            importedCnt++;
        }
    });

    const rerender = () => {
        setReloadTable(Math.random());
    };
    const csvImport = useCsvImport();

    const activationGroupsResult = useQuery(QUERY_ACTIVATION_GROUPS, {
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        }
    });

    const [createDevice] = useMutation(MUTATE_CREATE_DEVICE, {});

    /**
     *
     * @param d {ImportDevice}
     * @returns {Promise<ExecutionResult<any>>}
     */
    const createDeviceHandler = (d, meta) => {
        let configString = JSON.stringify(d.config);
        return createDevice({
            variables: {
                input: {
                    addr: d.address,
                    name: `${d.name}`,
                    deviceTypeId: meta.deviceType.id,
                    organisationId: meta.organisation?.id,
                    firmwareVersion: d.firmware,
                    initialConfig: configString,
                    activationGroupId: meta.activationGroupId,
                    serial: d.serial,
                }
            }
        });
    };

    const client = useApolloClient();

    useEffect(() => {
        Log.Debug("Load results");

        if (csvImport.results) {
            const data = csvImport.results.data;
            let colNames = [];
            colNames = data[0];
            let idxSerial = colNames.findIndex(v => v.toLowerCase() === "serial");
            let idxAddr = colNames.findIndex(v => v.toLowerCase() === "address");
            let idxConfig = colNames.findIndex(v => v.toLowerCase() === "config");
            let idxName = colNames.findIndex(v => v.toLowerCase() === "name");
            let idxFirmware = colNames.findIndex(v => v.toLowerCase() === "firmware");
            let cfgCols = colNames.map((c, i) => c.startsWith("cfg:") ? [c.replaceAll("cfg:", ""), i] : null).filter(c => c !== null);

            let devs = [];
            let devAddrToQuery = []
            for (let i = 1; i < data.length; i++) {
                let rec = data[i];
                // Note that rec[-1] === undefined
                const addr = rec[idxAddr];
                if (!addr) {
                    Log.Debug("Skip", idxAddr, rec, data[0]);
                    continue;
                }

                Log.Debug("Import", addr);
                let d = new ImportDevice(addr);
                d.createDeviceHandler = createDeviceHandler;
                d.serial = rec[idxSerial];

                d.name = rec[idxName] || d.address;
                d.firmware = rec[idxFirmware] || "";

                d.config = {};
                try {
                    if (rec[idxConfig] !== undefined) {
                        d.config = JSON.parse(rec[idxConfig]);
                    }
                    cfgCols.forEach(c => {
                        const val = rec[c[1]];
                        if (val !== undefined && val !== null && val !== "") {
                            d.config[c[0]] = val;
                        }
                    });
                } catch (err) {
                    d.configParseError = err.message;
                }


                if (addr) {
                    devAddrToQuery.push(addr)
                }
                devs.push(d);
            }
             client.query({
                 query: QUERY_DEVICES_BY_ADDRESS,
                 variables: {devAddr: String(devAddrToQuery)}
             }).then((res) => {
                 Log.Debug("Devices Result: ", res);
                 for (const dev of devs) {
                     if (res.data?.devices) {
                         dev.existingCount = res.data?.devices.filter(obj => {
                             return obj.addr === dev.address;
                         }).length
                     } else {
                         dev.existingCount = 0
                     }

                }

                 Log.Debug("Devices:", devs)
                 setDevices(devs);
             });
        }
    }, [csvImport.results]);

    /**
     *
     * @param devices {ImportDevice[]}
     * @param meta {any}
     * @returns {Promise<void>}
     */
    const runImport = (devices, meta) => {
        Log.Debug("Start import", devices);

        setImportRunning(true);
        let p = Promise.resolve();
        let i = 0;
        devices.forEach((d) => {
            i++;
            p = p.then(() => d.import(meta).then(() => {
                if (i % 10 === 0) {
                    Log.Debug("rerender");
                    rerender();
                }
                return Promise.resolve();
            }));

        });
        return p.then(() => {
            setImportRunning(false);
            rerender();
        });
    };

    return <Page
        title={"Import Hardware"}
        trail={[]}
        withPadding={true}
    >
        <Formik
            initialValues={{
                file: "",
                importExisting: false,
                deviceType: null,
                organisation: null,
            }}
            validate={(values) => {
                const errors = {};
                if (values.deviceType && values.organisation) {
                    if (values.deviceType.private && values.organisation.id != values.deviceType.organisationId) {
                        errors.deviceType = 'DeviceType not accessible for Organisation: ' +  values.organisation.id
                    }
                }
                return errors;
            }}
            onSubmit={(values, helpers) => {
                runImport(devices, values).then(() => {
                    helpers.setSubmitting(false);
                });
            }}
        >{(form) => {
            const file = form.values.file;

            Log.Debug("File:", file);
            return <Form>
                <SldsFileSelectorField label={"CSV File"} buttonLabel={"Select CSV"} name={"file"} required={false} accept={".csv"} onFileChange={(file) => {
                    csvImport.loadFile(file);
                }}/>
                <Accordion>
                    <AccordionPanel id={"0"} summary={"CSV Format"}>
                        <div className="slds-text-heading--small">CSV Format</div>
                        <div>Column names in first row:</div>
                        <DescriptionList>
                            <DescriptionListEntry label="address (required)">Address of the device, e.g. IMEI or DevEUI</DescriptionListEntry>
                            <DescriptionListEntry label="name">Name for the devices</DescriptionListEntry>
                            <DescriptionListEntry label="serial">Serial</DescriptionListEntry>
                            <DescriptionListEntry label="firmware">Name of the firmware incl. version</DescriptionListEntry>
                            <DescriptionListEntry label="config">Initial config in JSON format</DescriptionListEntry>
                            <DescriptionListEntry label="cfg:<key>">Initial config value for 'key' (overrides json config)</DescriptionListEntry>
                        </DescriptionList>
                    </AccordionPanel>
                </Accordion>
                <DeviceTypeLookupField/>
                <OrganisationLookupField/>
                <SingleLookupField
                    name={"activationGroupId"} label={"Activation Group"}
                    titleExtractor={it => it.nr}
                    subtitleExtractor={it => it.id}
                    valueExtractor={it => it.id}
                    loadSuggestions={keyword => activationGroupsResult.refetch({search: keyword})
                        .then(result => result.data.getActivationGroupList)}
                />
                <FormActions>
                    <SubmitButtonField iconName={"play"}>Run Import</SubmitButtonField>
                </FormActions>

                <div className="slds-m-top--x-small">Imported ({importedCnt} / {devices.length})</div>
                <ProgressBar current={importedCnt} max={devices.length}/>

                <div className="slds-text-heading--medium slds-m-top--small">Preview</div>
                {importRunning ? null :
                    <DataTable fixedLayout={false}>
                        <TableHead>
                            <HeaderCol>Serial</HeaderCol>
                            <HeaderCol>Name</HeaderCol>
                            <HeaderCol>Address</HeaderCol>
                            <HeaderCol>Firmware</HeaderCol>
                            <HeaderCol>Config</HeaderCol>
                            <HeaderCol>Exists</HeaderCol>
                            <HeaderCol>Import Status</HeaderCol>
                        </TableHead>
                        <TableBody>
                            {devices.map((d, i) => {
                                return <Row key={i}>
                                    <Col>{d.serial}</Col>
                                    <Col>{d.name}</Col>
                                    <Col>{d.address}</Col>
                                    <Col>{d.firmware}</Col>
                                    <Col wrap={true}>
                                        {!d.configParseError ?
                                            <Icon name="check" size={"small"} className="slds-m-right--x-small"/> :
                                            <Icon name="close" size={"small"} className="slds-m-right--x-small"/>}

                                        <Tooltip scrollable={true} left="-10px" top="-50px" content={() => {
                                            if (d.configParseError) {
                                                return <div>{d.configParseError}</div>;
                                            }

                                            return <div><Json json={d.config}/></div>;
                                        }}><Icon name="info" size={"small"}/></Tooltip>
                                    </Col>
                                    <Col>{d.existingCount > 0 ?
                                        <Tooltip left="-10px" top="-50px" content={`# Devices: ${d.existingCount}`}><Icon name="check" size={"small"}/></Tooltip> :
                                        <Icon name="close" size={"small"}/>} ({d.existingCount})</Col>
                                    <Col><Tooltip left="-10px" top="-50px" content={d.importStatus}>
                                        {d.importStatus === ImportStatus.Pending ? <Icon name="threedots" size={"small"}/> : null}
                                        {d.importStatus === ImportStatus.Done ? <Icon name="check" size={"small"}/> : null}
                                        {d.importStatus === ImportStatus.Failed ? <Icon name="close" size={"small"}/> : null}
                                        {d.importStatus === ImportStatus.Skipped ? <Icon name="skip" size={"small"}/> : null}
                                    </Tooltip></Col>
                                </Row>;
                            })}

                        </TableBody>
                    </DataTable>}
            </Form>;
        }}</Formik>
    </Page>;
}