import React, {useRef, useState} from "react";
import Page from "../../../common/ui/page";
import {Form} from "../../../common/ui/form/formik";
import {FormActions, SldsFileSelectorField, SubmitButtonField} from "../../../common/ui/form/formElements";
import {Icon} from "../../../common/slds/icons/icon";
import Tooltip from "../../../common/slds/tooltip/tooltip";
import {Formik} from "formik";
import {Log} from "../../../common/log";
import Papa, {ParseResult} from "papaparse";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import {useT} from "../../../common/i18n";
import {useNotificationContext} from "../../../notifications/notificationContext";
import {validateManufacturer, validateMeterId, validateMeterKey} from "./wmbusValidators";
import {useAuthContext} from "../../../common/context/authContext";
import {ColumnsType, ColumnType} from "antd/es/table";
import {Button, Input, InputRef, Space, Table} from "antd";
import {v4 as uuidv4} from 'uuid'
import ProgressBar from "../../../common/slds/progressBar/progressBar";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from 'react-highlight-words';

const WMBUS_KEYS = gql`
    query ($orgId: ID!, $page: PaginationInputType, $sort: SortInputType, $filter:[FilterInputType!]){
        getWmbusKeysForOrganisation(orgId: $orgId, sort: $sort, page: $page, filter: $filter) {
            result {
                id
                meterId
                manufacturer
                encryptionKey
            }
            totalCount
        }
    }
`;

const CREATE_WMBUS_KEY = gql`mutation($wmbusKey: WmbusKeyInput!){
    createWmbusKey(input: $wmbusKey){
        id
        meterId
    }}
`;


const exampleCsv = `meterId;manufacturer;key
12345678;LOB;12345678AAAAAAAA12345678AAAAAAAA
;LOB;aaaaaaaabbbbbbbbccccccccdddddddd
12345678;;FFFFFFFF12345678FFFFFFFF12345678
`

const ImportStatus = {
    Pending: "pending",
    Done: "done",
    Failed: "failed",
    Skipped: "skipped",
};


/**
 * meterId, key pair to be imported
 */
class KeyData {
    keyId: string
    meterId: string | null;
    manufacturer: string | null;
    encryptionKey: string;
    existing: boolean | null; // is there already an existing entry for the id and manufacturer combination?
    valid: string[] | null;
    importStatus: string | null ;

    constructor(meterId, key, manufacturer, existing, valid) {
        this.keyId = uuidv4()
        this.meterId = meterId;
        this.encryptionKey = key;
        this.manufacturer = manufacturer;
        this.importStatus = ImportStatus.Pending;
        this.existing = existing
        this.valid = valid
    }
}

function downloadExampleCsv() {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exampleCsv));
    element.setAttribute('download', "mbusKeysExample.csv");

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

type DataIndex = keyof KeyData | "";
export interface FilterConfirmProps {
    closeDropdown: boolean;
}
type CreationResult = {
    status: string,
    keyId: string
}

export default function WmbusKeyImportPage() {
    const t = useT();
    const notify = useNotificationContext()
    const auth = useAuthContext();
    const [keyViewData, setKeyViewData] = useState<KeyData[]>([]);
    const [importedCnt, setImportedCnt] = useState<number>(-1);
    const [runImportDisabled, setRunImportDisabled] = useState(false)
    const [searchedText, setSearchedText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const existingKeys = useQuery(WMBUS_KEYS, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
        variables: {
            orgId: auth.organisationId(),
            page: {
                offset: 0,
                limit: 100000,
            },
            sort: {
                field: "id",
                direction: "DESC"
            },
        }
    });

    const [createWmbusKey] = useMutation(CREATE_WMBUS_KEY, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
    });

    const loadCSVFile = (file) => {
        Log.Debug("Loading CSV File");
        Papa.parse(file, {
            skipEmptyLines: true,
            complete: (results) => {
                Log.Debug("Loading CSV File - DONE");
                processCSVResults(results)
            }
        });
        setImportedCnt(-1)
    }

    function existingCheck(meterId: string, manufacturer: string, key: string) : boolean {
        const nullToEmpty = (s: string) => {
            if (s === null || s === undefined) {
                return ''
            } else {
                return s
            }
        }
        return existingKeys.data?.getWmbusKeysForOrganisation?.result?.some(elem => (
            !!meterId === !!elem.meterId
            && nullToEmpty( elem.meterId) === nullToEmpty(meterId))
            && !!elem.manufacturer === !!manufacturer
            && nullToEmpty(elem.manufacturer) === nullToEmpty(manufacturer)
            && elem.encryptionKey === key.toUpperCase())
    }

    const processCSVResults = (results: ParseResult<unknown>) => {
        if (results) {
            const data = results.data;
            const colNames = data[0] as Array<string>;
            const idxMeterId = colNames.findIndex(v => v.toLowerCase() === "meterid");
            const idxManufacturer = colNames.findIndex(v => v.toLowerCase() === "manufacturer");
            const idxKey = colNames.findIndex(v => v.toLowerCase() === "key");

            const keyPairs: KeyData[] = [];

            for (let i = 1; i < data.length; i++) {
                const rec = data[i] as Array<string>;
                const validationErrors: string[] = []
                // Note that rec[-1] === undefined
                let key = rec[idxKey];
                if (!key) {
                    Log.Debug("Skip", idxKey, rec, data[0]);
                    //key is mandatory
                    continue;
                }
                key = key.toUpperCase().padStart(32, '0')
                const keyValid = validateMeterKey(t, key);
                if (keyValid !== undefined) {
                    validationErrors.push(keyValid as string);
                }

                const manufacturer = rec[idxManufacturer];
                if (!!manufacturer) {
                    const manufacturerValid = validateManufacturer(t, manufacturer);
                    if (manufacturerValid !== undefined) {
                        validationErrors.push(manufacturerValid as string);
                    }
                }

                let meterId = rec[idxMeterId];
                if (!!meterId) {
                    const meterIdValid = validateMeterId(t, meterId);
                    if (meterIdValid !== undefined) {
                        if (meterId === "*") {
                            meterId = ""
                        } else {
                            validationErrors.push(meterIdValid as string);
                        }
                    }
                }

                keyPairs.push(new KeyData(meterId, key, manufacturer, existingCheck(meterId, manufacturer, key), validationErrors));
            }

            setKeyViewData(keyPairs);
        }
    }

    const canDoImport = (key) => {
        const existingCheck = !key.existing
        const validCheck = key.valid.length === 0
        return validCheck && existingCheck
    }

    const runImport = (keysToImport) => {
        const promises: Promise<CreationResult>[] = []
        setImportedCnt(0)
        let localImportCnt = 0
        const newKeyViewData : KeyData[] = []
        keysToImport.forEach(key => {
            if (canDoImport(key)) {
                const promise = createWmbusKey({
                    variables: {
                        wmbusKey: {
                            meterId: key.meterId,
                            manufacturer: key.manufacturer,
                            encryptionKey: key.encryptionKey,
                        }
                    }
                }).then(() => {
                    localImportCnt++
                    setImportedCnt(localImportCnt)
                    key.importStatus = ImportStatus.Done
                    newKeyViewData.push(key)
                    return {status: ImportStatus.Done, keyId: key.keyId}
                }).catch((err) => {
                    console.log('update err:', err);
                    localImportCnt++
                    setImportedCnt(localImportCnt)
                    key.importStatus = ImportStatus.Failed
                    newKeyViewData.push(key)
                    return err.resolve({status: ImportStatus.Failed, keyId: key.keyId})
                })

                promises.push(promise)
            } else {
                localImportCnt++
                setImportedCnt(localImportCnt)
                key.importStatus = ImportStatus.Skipped
                newKeyViewData.push(key)
                promises.push(Promise.resolve({status: ImportStatus.Skipped, keyId: key.keyId}))
            }
        })
        Promise.all(promises).then((values) => {
            setKeyViewData(newKeyViewData)
            const failed = values.filter((v) => v.status === ImportStatus.Failed)
            if (failed.length === 0) { //"Only use {{allowed}}", {allowed: "[0-9, a-z, A-Z, -]"}
                notify.info(t("wmbus-key-import.import-success-notification", "imported keys"))
            } else {
                notify.error(t("wmbus-key-import.import-failed-notification", "error on key import: "))
            }
            existingKeys.refetch()
            setRunImportDisabled(true)
        });
    }

    const renderExisting = (existing) => {
        if (!!existing) {
            return <Tooltip
                left="-10px"
                top="-50px"
                content={t("wmbus-key-import.existing-key-for-filter", "Existing Key Entry for Filter found!")}>
                <Icon name="check" size={"small"}/>
            </Tooltip>
        } else {
            return <Icon name="close" size={"small"}/>
        }
    }
    const renderValid = (valid) => {
        if (!!valid && valid.length === 0) {
            return <Icon name="check" size={"small"} className="slds-m-right--x-small"/>
        } else {
            return <div className={"slds-grid"}>
                <Icon name="close" size={"small"} className="slds-m-right--x-small"/>
                <ul> {valid && valid?.map((v, i) => {
                    return <li key={i}>{v}</li>
                })
                }
                </ul>
            </div>
        }
    }

    const renderStatus = (status) => {
        switch (status) {
            case ImportStatus.Done:
                return  <Icon name="check" size={"small"}/>
            case ImportStatus.Failed:
                return  <Icon name="close" size={"small"}/>
            case ImportStatus.Skipped:
                return <Icon name="skip" size={"small"}/>
            case ImportStatus.Pending:
                return null
            default:
                return status

        }
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchedText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleSearchReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchedText('');
        setSearchedColumn('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<KeyData> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                      //  style={{minWidth: 90}}
                    >
                        {t("common.button.search", "Search")}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleSearchReset(clearFilters)}
                        size="small"
                   //     style={{width: 90}}
                    >
                        {t("org.config.wmbus.reset", "Reset")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchedText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<KeyData> = [
        {
            title: t("wmbus-key-import.meter-id", "MeterId"),
            dataIndex: 'meterId',
            key: 'meterId',
            ...getColumnSearchProps('meterId'),
        },
        {
            title: t("wmbus-key-import.manufacturer", "Manufacturer"),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            ...getColumnSearchProps('manufacturer'),
        },
        {
            title: t("wmbus-key-import.encryption-key", "Encryption KeyKey"),
            dataIndex: 'encryptionKey',
            key: 'encryptionKey',
            render: (text) => <div>{text}</div>,
        },
        {
            title: t("wmbus-key-import.existing", "Existing"),
            dataIndex: 'existing',
            key: 'existing',
            render: renderExisting
        },
        {
            title: <div>
                {t("wmbus-key-import.valid", "Valid")}
                <Tooltip left="-10px" top="-50px"
                         content={t("wmbus-key-import.tooltip-invalid-are-skipped", "Invalid Entry's will be skipped.")}>
                    <Icon name="info" size={"small"} className="slds-m-left--x-small"/>
                </Tooltip>
            </div>,
            key: 'valid',
            dataIndex: 'valid',
            render: renderValid,
        },
        {
            title: t("wmbus-key-import.importStatus", "Import Status"),
            dataIndex: 'importStatus',
            key: 'importStatus',
            render: renderStatus
        },
    ];

    let tableData = keyViewData
    if (searchedText !== ''){
        tableData = keyViewData.filter((v) => {return v[searchedColumn].includes(searchedText.toUpperCase())})
    }

    return <Page
        title={t("wmbus-key-import.title", "Import wMbus Keys")}
        trail={[]}
        withPadding={true}
    >
        <Formik
            initialValues={{
                file: "",
            }}
            onSubmit={() => {
                runImport(keyViewData);
            }}
        >{() => {
            return <Form>
                <SldsFileSelectorField label={t("wmbus-key-import.csv-file", "CSV File")}
                                       buttonLabel={t("wmbus-key-import.csv-file-button", "Select CSV")}
                                       name={"file"}
                                       required={false}
                                       accept={".csv"} onFileChange={(file) => {
                    loadCSVFile(file)
                    setRunImportDisabled(false)
                }}/>
                <Button onClick={() => downloadExampleCsv()}>
                    {t("wmbus-key-import.download-button", "Download Example CSV")}
                </Button>
                <FormActions>
                    <SubmitButtonField iconName={"play"} label={undefined} formId={undefined} disabled={runImportDisabled}
                                       onClick={undefined}>{t("wmbus-key-import.run-import-button", "Run Import")}</SubmitButtonField>
                </FormActions>
                { importedCnt >= 0 ?
                    <div>
                        <div className="slds-m-top--x-small">Imported ({importedCnt} / {keyViewData.length})</div>
                        <ProgressBar current={importedCnt} max={keyViewData.length}/>
                     </div>
                    : null
                }
                <div className="slds-text-heading--medium slds-m-top--small">
                    {t("wmbus-key-import.table", "Keys to Import")}
                </div>
                <Table columns={columns} dataSource={tableData} rowKey={(record) => record.keyId}/>
            </Form>;
        }}</Formik>
    </Page>
}