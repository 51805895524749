import { useT } from "../../../common/i18n"
import { Link } from "react-router-dom"
import { useAuthContext } from "../../../common/context/authContext"
import React, { ReactElement, useState } from "react"
import Page from "../../../common/ui/page"
import { ButtonGroup } from "@salesforce/design-system-react"


import { useGraphqlLoadingComponent } from "../../../common/graphql"
import AddOrganisationDialog from "../../../components/organisation/addOrganisationDialog"
import { useMutation, useQuery } from "@apollo/client"
import { Button, Table } from "antd"
import { Log } from "../../../common/log"
import { ColumnType } from "antd/es/table"
import { useNotificationContext } from "../../../notifications/notificationContext"
import { MUTATION_DELETE_ORGANISATION, MUTATION_UPDATE_ORGANISATION, MUTATION_UPDATE_USER_ORG, QUERY_DEEP_SUBORGANISATION_TREE } from "./queries"
import CellEditForm from "./CellEditForm"

type EditableColumnsType<RecordType = unknown> = (ColumnType<RecordType> & { editable?: boolean })[]


interface ParentOrganisation {
    id: number,
    name: string,
    maxSubOrgDepth: number,
    effectiveSubOrgDepth: number,
}
interface Organisation {
    id: number
    name: string
    numDevices: number
    numUsers: number
    maxSubOrganisations: number
    maxSubOrgDepth: number
    effectiveSubOrgDepth: number
    parentOrganisation?: ParentOrganisation
    subOrganisations: Organisation[]
}

export const SubOrganisationPage = () => {
    const t = useT()
    const auth = useAuthContext()
    const notify = useNotificationContext()
    const [editingId, setEditingId] = useState<string>()
    const deepOrgTreeResult = useQuery(QUERY_DEEP_SUBORGANISATION_TREE, {
        fetchPolicy: "network-only",
        variables: {
            id: auth.organisationId(),
        },
    })
    const [showNewSubOrgaModal, setShowNewSubOrgaModal] = useState(false)

    if (!auth.hasRole("org-admin") && !auth.hasRole("admin")) {
        return null
    }

    const [mutateUpdateUser] = useMutation(MUTATION_UPDATE_USER_ORG)

    const [updateOrganisation] = useMutation(MUTATION_UPDATE_ORGANISATION)

    const [mutateDeleteOrganisation] = useMutation(MUTATION_DELETE_ORGANISATION)

    const loading = useGraphqlLoadingComponent(deepOrgTreeResult, {})
    if (loading) {
        return loading
    }
    const isEditing = (id: string) => id === editingId

    const isEditMaxSubOrgsAllowed = (targetOrg: number) => {
        return !(targetOrg === auth.organisationId() && !auth.isOrgAdminOfParentOrg())
    }

    const columns: EditableColumnsType<Organisation> = [
        {
            title: t("org.config.suborgs.name", "Organisation Name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("org.config.suborgs.sub-organisations", "Sub-Organisations"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("org.config.suborgs.devices", "Devices"),
            dataIndex: "numDevices",
            key: "numDevices",
        },
        {
            title: t("org.config.suborgs.Users", "Users"),
            dataIndex: "numUsers",
            key: "numUsers",
        },
        {
            title: t("org.config.suborgs.max-suborgs", "Max SubOrgs"),
            dataIndex: "maxSubOrganisations",
            key: "maxSubOrganisations",
            render: (value: number, record: Organisation) => {
                if (isEditing(`maxSubOrganisations-${record.id}`)) {
                    return <CellEditForm
                        value={record.maxSubOrganisations}
                        onChange={(val) => {
                            triggerUpdateOrganisation(record.id, null, val)
                                .then(() => {
                                    setEditingId(undefined)
                                })
                        }}
                        reset={() => setEditingId(undefined)} />
                } else {
                    return value === -1 ? "∞" : <> {value}
                        {isEditMaxSubOrgsAllowed(record.id) &&
                            <svg className={"slds-button__icon slds-m-left--x-small"} aria-hidden="true">
                                <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#edit`} />
                            </svg>}
                    </>
                }
            },
            onCell: (record: Organisation) => {
                if (!isEditing(`maxSubOrganisations-${record.id}`) && isEditMaxSubOrgsAllowed(record.id)) {
                    return {
                        onClick: () => setEditingId(`maxSubOrganisations-${record.id}`),
                    }
                } else {
                    return {}
                }
            },
        },
        {
            title: t("org.config.suborgs.configured-max-depth", "Configured Max Depth"),
            dataIndex: "maxSubOrgDepth",
            key: "maxSubOrgDepth",
            render: (value: number, record: Organisation) => {
                let maxValue = record.parentOrganisation?.effectiveSubOrgDepth || undefined
                if (!!maxValue && maxValue >= 1 ) {
                    maxValue = maxValue - 1
                }
                if (isEditing(`maxSubOrgDepth-${record.id}`)) {
                    return <CellEditForm
                        value={record.maxSubOrgDepth}
                        max={maxValue}
                        onChange={(val) => {
                            triggerUpdateOrganisation(record.id, val, null)
                                .then(() => {
                                    setEditingId(undefined)
                                })
                        }}
                        reset={() => setEditingId(undefined)} />
                } else {
                    return value === -1 ? "∞" : <>
                        {value}
                        <svg className={"slds-button__icon slds-m-left--x-small"}  aria-hidden="true">
                            <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#edit`} />
                        </svg>

                    </>
                }
            },
            onCell: (record: Organisation) => {
                if (!isEditing(`maxSubOrgDepth-${record.id}`)) {
                    return {
                        onClick: () => setEditingId(`maxSubOrgDepth-${record.id}`),
                    }
                } else {
                    return {}
                }
            },
        },
        {
            title: t("org.config.suborgs.effectiv-max-depth", "Effective Max Depth"),
            dataIndex: "effectiveSubOrgDepth",
            key: "effectiveSubOrgDepth",
        },
    ]

    if (auth.hasRole("admin", "org-admin")) {
        columns.push({
                title: t("org.config.suborgs.operations", "Operations"),
                dataIndex: "operation",
                render: (_, org: Organisation) => {
                    return <>
                        <Button
                            onClick={() => {
                                mutateUpdateUser({
                                    variables: {
                                        id: auth.userId(),
                                        input: {
                                            organisationId: org.id,
                                        },
                                    },
                                }).then(() => {
                                    auth.refreshTheToken().then(() => {
                                        location.reload()
                                    })
                                }).catch((err) => {
                                    Log.Error("Failed to update user", err)
                                })
                            }
                            }
                        >
                            <svg className={"slds-button__icon slds-m-right--x-small"} aria-hidden="true">
                                <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#right`} />
                            </svg>
                            {t("org.config.suborgs.jump-inot", "Jump into")}
                        </Button>{org.numDevices == 0 && org.numUsers == 0 && (org.subOrganisations == null || org.subOrganisations.length == 0) ?
                        <Button
                            onClick={() => {
                                mutateDeleteOrganisation({
                                    variables: {
                                        id: org.id,
                                    },
                                }).then(() => {
                                    auth.refreshTheToken().then(() => {
                                        location.reload()
                                    })
                                }).catch((err) => {
                                    Log.Error("Failed to delete organisation", err)
                                })
                            }
                            }
                        >
                            <svg className={"slds-button__icon slds-m-right--x-small"} aria-hidden="true">
                                <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#delete`} />
                            </svg>
                            {t("common.button.delete", "Delete")}
                        </Button> : null}
                    </>
                },
            },
        )
    }

    const organisation = deepOrgTreeResult.data?.getOrganisationDeepload

    const triggerUpdateOrganisation = (orgId: number, maxSubOrgDepth: number | null, maxSubOrgs: number | null) => {
        return updateOrganisation({
            variables: {
                orgId: orgId,
                organisation: {
                    maxSubOrgDepth: maxSubOrgDepth,
                    maxSubOrganisations: maxSubOrgs,
                },
            },
        }).then(() => {
                notify.success(t("org.config.suborgs.success", "Successfully updated organisation"))
                void deepOrgTreeResult.refetch()
            },
        ).catch((err) => {
            notify.error(t("org.config.suborgs.error", "Error updating organisation"), err)
            void deepOrgTreeResult.refetch()
        })
    }

    let parentSubOrgElem: ReactElement | null = null
    if (organisation.parentOrganisation) {
        if (auth.isOrgAdminOfParentOrg()) {
            parentSubOrgElem = <>• Parent: <a onClick={() => {
                mutateUpdateUser({
                    variables: {
                        id: auth.userId(),
                        input: {
                            organisationId: organisation.parentOrganisation.id,
                        },
                    },
                }).then(() => {
                    auth.refreshTheToken().then(() => {
                        location.reload()
                    })
                }).catch((err) => {
                    Log.Error("Failed to update user", err)
                })
            }}>{organisation.parentOrganisation.name}</a></>
        } else {
            parentSubOrgElem = <>{" • Parent: " + organisation.parentOrganisation.name}</>
        }
    }

    return <Page
        trail={[<Link to={window.location.href}
                      key={1}>{t("org.config.suborganisations.title", "Sub-Organisations")}</Link>]}
        title={t("org.config.suborganisations.title", "Sub-Organisations")}
        info={subOrganisationsLeft(organisation) === -1 ? "∞" : <>
            <>{`${subOrganisationsLeft(organisation)} ${t("org.config.suborganisations.amount_left", {count: 1,  defaultValue: "Sub-Organisations left"})} • ${t("org.config.suborganisations.max-depth-to-use","max Depth to use")}: ${organisation.effectiveSubOrgDepth}`}</>
            {parentSubOrgElem}
        </>}
        actions={subOrganisationsLeft(organisation) !== 0 && organisation.effectiveSubOrgDepth > 0 ?
            <ButtonGroup><Button onClick={() => setShowNewSubOrgaModal(true)}>
                <svg className={"slds-button__icon slds-m-right--x-small"} aria-hidden="true">
                    <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#add`} />
                </svg>
                {t("org.config.suborganisations.new-suborg", "New Sub-Organisation")}</Button></ButtonGroup> : null}
    >
        <Table
            bordered
            dataSource={[organisation]}
            columns={columns}
            rowKey={(record) => record.id}
            expandable={{
                childrenColumnName: "subOrganisations",
                defaultExpandAllRows: true,
            }}
        />

        <AddOrganisationDialog isOpen={showNewSubOrgaModal} onRequestClose={() => setShowNewSubOrgaModal(false)}
                               onAdded={() => deepOrgTreeResult.refetch()}
                               parentOrgId={auth.organisationId()}
                               onlyCloseAfterCreation={true}
        />
    </Page>
}


function subOrganisationsLeft(organisation: Organisation): number {
    let subOrgsLeft: number = organisation.maxSubOrganisations
    if (subOrgsLeft !== -1 && organisation.subOrganisations) {
        subOrgsLeft = Math.max(organisation.maxSubOrganisations - organisation.subOrganisations.length, 0)
    }
    return subOrgsLeft
}
