import React from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {useAuthContext} from "../common/context/authContext";
import {download} from "../common/download";
import {Icon, ScopedNotification} from "@salesforce/design-system-react";
import {toCsvLine} from "../common/hooks/useCsvExport";
import Tooltip from "../common/slds/tooltip/tooltip";
import {Log} from "../common/log";
import _ from "underscore";
import Button from "../common/slds/buttons/button";
import Spinner from "../common/slds/spinner/spinner";
import {useT} from "../common/i18n";

const exportAllToCsv = (hardwareByDeviceType) => {
    let data = "\ufeff"; // UTF-8 Header
    let heading = ["Address", "Firmware", "Device Type"];
    const deviceTypes = Object.keys(hardwareByDeviceType)
        .map(deviceTypeId => hardwareByDeviceType[deviceTypeId][0].deviceType);
    deviceTypes.forEach(deviceType => {
        let configProperties = JSON.parse(deviceType.configProperties || "[]");
        //Log.Debug("deviceType.configProperties", deviceType.configProperties);
        if (!_.isArray(configProperties)) {
            // Backward compatibility to old empty objects
            configProperties = [];
        }
        configProperties
            .filter(cfg => cfg.exported)
            .forEach(cfg => {
                if (!heading.includes(cfg.name)) heading.push(cfg.name)
            });
    });
    data += toCsvLine(heading);

    Object.values(hardwareByDeviceType)
        .forEach(hardwareList => {
            hardwareList.forEach(device => {
                let line = [device.addr, device.firmwareVersion, device.deviceType.displayName];
                let configProperties = JSON.parse(device.deviceType.configProperties || "[]");
                if (!_.isArray(configProperties)) {
                    // Backward compatibility to old empty objects
                    configProperties = [];
                }
                const initialConfig = JSON.parse(device.initialConfigRaw);
                for (let i = 3; i < heading.length; ++i) {
                    const col = heading[i];
                    let prop = configProperties.find((p) => p.name === col);
                    if (initialConfig && initialConfig[col] && prop?.exported) {
                        line.push(initialConfig[col]);
                    } else {
                        line.push("");
                    }
                }
                data += toCsvLine(line);
            });
        });
    download(data, "hardware.csv", "text/csv");
};

const exportDeviceTypeToCsv = (configProperties, hardwareList, filename) => {
    Log.Debug("configProperties", configProperties);
    Log.Debug("hardwareList", hardwareList);
    let data = "\ufeff"; // UTF-8 Header
    let heading = ["Address", "Firmware"];
    if (!_.isArray(configProperties)) {
        // Backward compatibility to old empty objects
        configProperties = [];
    }
    configProperties.filter(cfg => cfg.exported)
        .forEach(cfg => heading.push(cfg.name));
    data += toCsvLine(heading);

    hardwareList.forEach(device => {
        let line = [device.addr, device.firmwareVersion];
        const initialConfig = JSON.parse(device.initialConfigRaw);

        for (let i = 2; i < heading.length; ++i) {
            const col = heading[i];
            let prop = configProperties.find((p) => p.name === col);
            if (initialConfig && initialConfig[col] && prop?.exported) {
                line.push(initialConfig[col]);
            } else {
                line.push("");
            }
        }
        data += toCsvLine(line);
    });
    download(data, filename, "text/csv");
};

const HardwareActivationTable = () => {
    const location = useLocation();
    const auth = useAuthContext();
    const {activationCode, orgId} = useParams();
    const t = useT()

    // TODO: Claim status could be moved to GraphQL API
    const hardwareResult = useQuery(gql`
        query($activationCode: String, $orgId: ID) {
            devices(activationCode: $activationCode, orgId: $orgId) {
                id
                addr
                deviceType {
                    id
                    displayName
                    configProperties
                }
                initialConfigRaw
                firmwareVersion
                organisation {
                    id
                    name
                }
            }
        }
    `, {
        variables: {
            activationCode: activationCode,
            orgId: orgId,
        }
    });

    const [claimDevices] = useMutation(gql`
        mutation($deviceIds: [Int!]) {
            claimDevices(ids: $deviceIds)
        }
    `);

    if (orgId !== auth.organisationId().toString()) {
        return <h1>YOU DONT HAVE PERMISSION TO SEE DEVICES FOR THE ORGANISATION WITH ID: {orgId}</h1>
    }

    if (hardwareResult.loading) {
        return <Spinner/>;
    }

    const ClaimDevicesButton = (props) => {
        if (!auth.isLoggedIn()) {
            return <></>;
        }
        return <Button onClick={() => {
            return claimDevices({
                variables: {
                    deviceIds: props.deviceIds
                }
            })
                .then(() => hardwareResult.refetch());
        }}
        >{props.label}</Button>;
    };

    const hardware = hardwareResult.data.devices;

    const hardwareByDeviceType = {};
    for (let i = 0; i < hardware.length; i++) {
        const deviceTypeId = hardware[i].deviceType.id;
        if (!hardwareByDeviceType.hasOwnProperty(deviceTypeId)) {
            hardwareByDeviceType[deviceTypeId] = [];
        }
        hardwareByDeviceType[deviceTypeId].push(hardware[i]);
    }

    if (hardware.length === 0) {
        return <div
            className="slds-m-vertical--small">{t("hardware-activation.no-hardware-found", "Sorry, no hardware found. Please double-check your activation code.")}</div>;
    }

    return <>
        <div
            className="slds-text-heading--large slds-m-top--large slds-m-bottom--small">{t("hardware-activation.your-hardware", "Your Hardware")}</div>
        {!auth.isLoggedIn() && <ScopedNotification
            className="slds-m-bottom--medium"
            icon={<Icon
                assistiveText={{
                    label: 'Warning',
                }}
                category="utility"
                colorVariant="warning"
                name="warning"
                size="small"
            />}
            theme="dark"
        >
            <div className="slds-grid slds-grid_align-spread">
                <h2 className="slds-align-middle">To see the data provided by these devices/Organisation, please create
                    an account
                    and sign in.</h2>
                <Link
                    className="slds-m-left--small slds-align-bottom"
                    to={`/auth/register?r=${location.pathname}`}>
                    <Button>Create an account</Button>
                </Link>
            </div>
        </ScopedNotification>
        }

        {Object.keys(hardwareByDeviceType).length > 0 &&
            <Button onClick={() => exportAllToCsv(hardwareByDeviceType)} iconName="download">CSV Export</Button>}
        {activationCode ? <ClaimDevicesButton deviceIds={hardware.map(h => h.id)} label={"Claim all"}/> : null}

        {Object.keys(hardwareByDeviceType).map(deviceTypeId => {
            const hardwareList = hardwareByDeviceType[deviceTypeId];
            const deviceType = hardwareList[0].deviceType;
            let configProperties = JSON.parse(deviceType.configProperties) || [];
            if (!_.isArray(configProperties)) {
                // Backward compatibility to old empty objects
                configProperties = [];
            }
            Log.Debug("configProperties", configProperties, deviceType.configProperties);
            const visibleConfigProperties = configProperties && configProperties.filter(cp => cp.visible) || [];
            return <div key={deviceTypeId} className="slds-card">
                <div className="slds-card__header slds-grid">
                    <header className="slds-media slds-media_center slds-has-flexi-truncate">
                        <div className="slds-media__figure">
                        <span className="slds-icon_container slds-icon-standard-account" title="Devices">
                          <svg className="slds-icon slds-icon_small" aria-hidden="true">
                            <use href="/assets/icons/standard-sprite/svg/symbols.svg#account"></use>
                          </svg>
                          <span className="slds-assistive-text">Devices</span>
                        </span>
                        </div>
                        <div className="slds-media__body">
                            <h2 className="slds-card__header-title">
                                <a className="slds-card__header-link slds-truncate"
                                   href="#"
                                   onClick={() => false}
                                   title="Devices">
                                    <span>{deviceType.displayName} ({hardwareList.length})</span>
                                </a>
                            </h2>
                        </div>
                        <div className="slds-no-flex">
                            <Button
                                onClick={() => exportDeviceTypeToCsv(configProperties, hardwareList, deviceType.displayName + ".csv")}
                                iconName={"download"}
                            >
                                CSV Export
                            </Button>
                            {activationCode ? <ClaimDevicesButton deviceIds={hardwareList.map(dev => dev.id)}
                                                                  label={"Claim devices"}/> : null}
                        </div>
                    </header>
                </div>
                <div className="slds-card__body slds-card__body_inner">
                    <table id={"hardware-overview"} className="slds-table slds-table--bordered">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Address</th>
                            <th>Firmware</th>
                            {visibleConfigProperties.map((cfg, i) => <th key={i}>{cfg.name}</th>)}
                            {activationCode ? <th>Claim status</th> : null}
                        </tr>
                        </thead>
                        <tbody>
                        {hardwareList.map((device, i) => {
                            const deviceOrgId = device.organisation && parseInt(device.organisation.id);
                            const initialConfig = JSON.parse(device.initialConfigRaw);
                            return <tr key={device.id}>
                                <td>{i + 1}</td>
                                <td style={{width: "200px"}}>{device.addr}</td>
                                <td>{device.firmwareVersion}</td>
                                {visibleConfigProperties.map((cfgProp, i) => <td key={i}>
                                    {initialConfig ? initialConfig[cfgProp.name] : ""}
                                </td>)}
                                {activationCode ? <td style={{width: "100px"}}>
                                    {device.organisation ?
                                        <Tooltip
                                            content={deviceOrgId === auth.organisationId() ? "Already claimed" : "Already claimed by other organisation"}
                                            top="-50px" left="-5px"
                                        >
                                            <Icon
                                                assistiveText={{
                                                    label: deviceOrgId === auth.organisationId() ?
                                                        "Already claimed" :
                                                        "Claimed by a different organisation",
                                                }}
                                                category="utility"
                                                colorVariant={deviceOrgId === auth.organisationId() ? "default" : "error"}
                                                name={deviceOrgId === auth.organisationId() ? "check" : "warning"}
                                                size="small"
                                            />
                                        </Tooltip>
                                        :
                                        <ClaimDevicesButton deviceIds={[device.id]} label={"claim"}/>
                                    }
                                </td> : null}
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </div>
            </div>;
        })}
    </>;
};

export default HardwareActivationTable;