import React from "react";
import Page from "../../common/ui/page";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {useAuthContext} from "../../common/context/authContext";
import {Link, Navigate, Outlet, useMatch} from "react-router-dom";
import Url from "../../common/url";
import PropTypes from "prop-types";
import {DeviceTypesNav} from "./deviceTypesNav";
import {useT} from "../../common/i18n";

export const QUERY_DEVICE_TYPES = gql`
    query($orgId: ID, $onlyWithExistingDevices: Boolean) {
        deviceTypes(orgId: $orgId, onlyWithExistingDevices: $onlyWithExistingDevices) {
            id
            displayName
            dataTableConfigRaw
            deviceTableConfigRaw
        }
    }
`;

DeviceTypesNav.propTypes = {
    deviceTypes: PropTypes.array,
};

export const QUERY_DEVICE_TYPE = gql`
    query deviceType($devTypeId: ID!) {
        deviceType(id: $devTypeId) {
            id
            displayName
            deviceTableConfigRaw
        }
    }
`;

const DeviceDataPage = () => {
    const t = useT();
    const auth = useAuthContext();
    const match = useMatch("/organisation/deviceData/:devTypeId");
    const devTypeId = match?.params?.devTypeId;

    const noSelectMatch = useMatch("/organisation/deviceData");

    const gqlResult = useQuery(QUERY_DEVICE_TYPES, {
            variables: {
                orgId: auth.organisationId(),
                onlyWithExistingDevices: true
            }
        }
    );
    const devTypeResult = useQuery(QUERY_DEVICE_TYPE, {
            skip: !devTypeId,
            variables: {
                devTypeId: devTypeId,
            }
        }
    );
    const devType = devTypeResult.data?.deviceType;
    const deviceTypes = gqlResult?.data?.deviceTypes;

    if (deviceTypes?.length >= 1 && noSelectMatch) {
        return <Navigate to={Url.join("/organisation/deviceData", deviceTypes[0].id)}/>
    }

    return <div className="slds-container--fluid">
        <div className="slds-grid">
            {deviceTypes?.length !== 1 && <div className="slds-col slds-no-flex">
                <DeviceTypesNav deviceTypes={deviceTypes} baseUrl={"/organisation/deviceData"}/>
            </div>}
            <div className="slds-col">
                <Page title={devType?.displayName || "Device Data"} trail={[<Link to={"."}  key={1}>{t("device-data.titel","Device Data")}</Link>]}>
                    <div className="slds-grid">
                        <Outlet/>
                    </div>
                </Page>
            </div>
        </div>
    </div>;
};

export default DeviceDataPage;